<template>
   <v-dialog v-model="visible" fullscreen>
        <v-card>
            <v-card-title class="mb-6">
                <v-spacer/>
                <span>
                    {{ item ? 'Detalhes do Produto' : 'Detalhes do Pedido' }}
                </span>
                <v-spacer/>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-if="item">
                <v-row>
                    <v-col cols="12">
                        <b>{{ item.descricao }}</b>
                    </v-col>
                </v-row>

                <StepsList :item="item"/>

                <div v-for="(product, i) in item.produtos" :key="i" class="mt-1">
                    <v-row class="ml-1">
                        <v-col cols="12">
                            <b>{{ product.descricao }}</b>
                        </v-col>
                    </v-row>

                    <StepsList :item="product" :tab="8" :combo="true"/>

                    <v-divider style="margin: 5px;"/>
                </div>

                <v-divider/>

                <v-row class="mt-1">
                    <v-col cols="12">
                        <i>{{ item.observacao }}</i>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text
                v-else
                :style="`max-width: 800px; margin: auto; max-height: ${$vuetify.breakpoint.height - 130}px; overflow-x: auto; padding: 30px; border: 1px solid #ddd; border-radius: 5px;`"
            >
                <v-row>
                    <v-col cols="12" sm="6">
                        <b>DATA/HORA:</b> {{ datahora(value.created_at) }}
                    </v-col>
                </v-row>
                <v-divider class="my-4"/>
                <v-row>
                    <v-col>
                        <b>FORMA DE ENTREGA:</b> {{ getEntrega(value.entrega) }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            @change="onChangeFormaPgto"
                            v-model="form.forma_pagamento"
                            :items="formasPagamento"
                            label="Forma de Pagamento"
                            item-value="id"
                            item-text="descricao"
                            hide-details
                            outlined
                            dense
                        />
                        <span v-if="valorAcrescimo(form.forma_pagamento)">
                            Acréscimo: R${{ formatPrice(valorAcrescimo(form.forma_pagamento)) }}
                        </span>
                    </v-col>
                </v-row>
                <v-row v-if="isDelivery">
                    <v-col>
                        <v-text-field
                            :readonly="isFinalizado"
                            label="Taxa de Entrega"
                            v-model="form.valor_frete"
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                            prefix="R$"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="isDelivery" cols="12" sm="6">
                        <b>TOTAL PRODUTOS:</b> R$ {{ formatPrice(getSubtotalGeral) }}
                    </v-col>
                    <v-col cols="12" sm="6">
                        <b>TOTAL FINAL:</b> R$ {{ formatPrice(+value.total || 0) }}
                    </v-col>
                </v-row>
                <v-row v-if="value.troco && isDelivery">
                    <v-col>
                        <v-text-field
                            :readonly="isFinalizado"
                            label="Troco para:"
                            v-model="form.troco"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>
                <v-divider class="my-4"/>
                <div v-if="isDelivery" class="mb-4">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                readonly
                                filled
                                label="Municípo"
                                v-model="form.municipio"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                readonly
                                filled
                                label="Bairro"
                                v-model="form.bairro"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                readonly
                                filled
                                label="Rua"
                                v-model="form.rua"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                readonly
                                filled
                                label="Número"
                                v-model="form.numero"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-radio-group
                                v-model="form.tipo_local"
                                disabled
                                readonly
                                row
                                hide-details
                                style="margin-top: 5px;"
                            >
                                <v-radio
                                    label="Casa"
                                    value="casa"
                                />
                                <v-radio
                                    label="Apartamento"
                                    value="apartamento"
                                />
                                <v-radio
                                    label="Trabalho"
                                    value="trabalho"
                                />
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="form.tipo_local === 'apartamento'">
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                filled
                                hide-details
                                label="Nome do prédio"
                                v-model="form.nome_edificio"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                filled
                                hide-details
                                label="Nº do apartamento"
                                v-model="form.numero_apartamento"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                readonly
                                filled
                                label="Complemento"
                                v-model="form.complemento"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </div>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Observação"
                            v-model="form.observacao"
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            @click="editarCliente"
                            color="primary"
                            outlined
                        >
                            <v-icon>mdi-pencil</v-icon>
                            Editar Dados
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mt-4">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="callWhatsapp()"
                                fab
                                icon
                                color="success"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-whatsapp</v-icon>
                            </v-btn>
                        </template>
                        <span>Chamar no WhatsApp</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="callPhone()"
                                fab
                                icon
                                color="pink"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-phone</v-icon>
                            </v-btn>
                        </template>
                        <span>Ligar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="viewLocation()"
                                fab
                                icon
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-map-marker</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver localização</span>
                    </v-tooltip>
                </div>

                <div>
                    <v-divider/>
                    <h2 class="title text-center mt-4">Histórico de alterações</h2>
                    <v-timeline align-top dense>
                        <v-timeline-item
                            :color="i % 2 ? 'pink' : 'teal lighten-3'"
                            small
                            v-for="(h, i) in value.alteracoes_historico"
                            :key="i"
                        >
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>{{ formatDate(h.updated_at, 'HH:mm') }}</strong>
                                </v-col>
                                <v-col>
                                    <strong>{{ translate(h.status) }}</strong>
                                    <div v-if="h.user_id" class="text-caption">
                                        por {{ h.user_id }} - {{ h.user_name }}
                                    </div>
                                    <div class="text-caption">
                                        em {{ formatDate(h.updated_at) }}
                                    </div>
                                    <v-divider/>
                                    <div class="text-caption">
                                        <b>Produtos:</b>
                                    </div>
                                    <div v-for="(produto, i) in h.produtos" :key="i" class="text-caption">
                                        {{ produto.quantidade }}x {{ produto.descricao }}
                                    </div>
                                    <v-divider/>
                                    <div class="text-caption">
                                        <b>TOTAL:</b> R${{ formatPrice(h.total) }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </v-card-text>
        </v-card>
        <v-footer fixed class="pa-4" style="max-width: 800px; margin: auto;">
            <v-btn color="primary" @click="close" block>Voltar</v-btn>
        </v-footer>
    </v-dialog>
</template>

<script>
import StepsList from './StepsList';
import {
    subtotalGeral,
} from '@/utils/calc';

export default {
    name: 'DialogDetalhes',

    components: { StepsList },

    props: [
        'visible',
        'item',
        'value',
        'formasPagamento',
        'formasEntrega'
    ],

    data: () => ({
        form: {},
        saving: false,
    }),

    computed: {
        isDelivery() {
            return this.value.entrega == 2;
        },

        isFinalizado() {
            return this.value.finalizado;
        },

        getSubtotalGeral() {
            return subtotalGeral(this.value?.produtos || []);
        },
    },

    watch: {
        value(comanda) {
            this.fillForm(comanda);
        },

        visible(v) {
            v ? this.fillForm(this.value) : this.$emit('close');
        },
    },

    methods: {
        fillForm(comanda) {
            this.form = {};

            this.form.troco = comanda.troco;
            this.form.valor_frete = comanda.valor_frete;
            this.form.forma_pagamento = comanda.forma_pagamento;

            this.fillAddress();
        },

        saveAddress() {
            this.value.observacao = this.form.observacao;
            this.value.troco = this.form.troco;
            this.value.valor_frete = this.form.valor_frete;
            this.value.forma_pagamento = this.form.forma_pagamento?.id || this.form.forma_pagamento || null;
        },

        fillAddress() {
            const endereco = this.value?.cliente?.enderecos[0];
            if (endereco) {
                this.form = { ...this.form, ...endereco };
                this.form.complemento = endereco.referencia;
                this.form.observacao = this.value.observacao || endereco.observacao;
            }

            this.saveAddress();
        },

        getEndereco() {
            return this.value.cliente?.enderecos[0] || {};
        },

        editarCliente() {
            this.close();
            this.$emit('editarCliente');
        },

        close() {
            this.saveAddress();
            this.$emit('close');
        },

        callWhatsapp() {
            const phone = '55' + this.value.whatsapp.replace(/\D/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${phone}`);
        },

        callPhone() {
            const phone = this.value.whatsapp.replace(/\D/g, '');
            window.open('tel:' + phone);
        },

        viewLocation() {
            if (!this.getEndereco()?.coordenadas) {
                this.notify('Cliente não enviou a localização', 'warning');
                return;
            }

            const c = this.getEndereco().coordenadas;
            window.open(`https://maps.google.com/?q=${c.latitude},${c.longitude}`);
        },

        getEntrega(id) {
            return (this.formasEntrega?.find(e => e.id == id))?.descricao || '';
        },

        getPagamento(id) {
            return (this.formasPagamento?.find(e => e.id == id))?.descricao || '';
        },

        datahora(dh) {
            return dh ? this.moment(dh).format('DD/MM/YYYY HH:mm') : '';
        },

        onChangeFormaPgto() {
            this.value.acrescimo = this.valorAcrescimo(this.form.forma_pagamento);
            this.$forceUpdate();
        },

        valorAcrescimo(formaPgtoId) {
            if (!formaPgtoId) {
                return 0;
            }

            const formaPgto = this.formasPagamento.find(f => f.id == formaPgtoId);

            const totPed = this.getSubtotalGeral + +this.value.valor_frete;
            const percentual = +(formaPgto.acrescimo_percentual || 0);
            const valor = +(formaPgto.acrescimo_valor) || 0;

            let acrescimo = 0;

            valor && (acrescimo += valor);
            percentual && (acrescimo += totPed * percentual / 100);

            return Math.round((acrescimo || 0) * 100) / 100;
        },

        translate(s) {
            if (s == 'pago') return 'Pago';
            if (s == 'recebido') return 'Recebido';
            if (s == 'concluido') return 'Concluido';
            if (s == 'cancelado') return 'Cancelado';
            if (s == 'confirmado') return 'Confirmado';
            if (s == 'em_preparacao') return 'Em Preparação';
            if (s == 'aguardando_retirada') return 'Aguardando Retirada';
            if (s == 'saiu_para_entrega') return 'Saiu para Entrega';
            return s;
        },
    }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
    word-break: normal;
    /* maybe !important  */
}

.v-card__title {
    padding: 0 16px 0 16px;
    font-size: 15px;
    height: 35px !important;
}
</style>
